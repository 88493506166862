// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-background-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/background-bitcoin.js" /* webpackChunkName: "component---src-pages-background-bitcoin-js" */),
  "component---src-pages-company-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-email-sent-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/email-sent.js" /* webpackChunkName: "component---src-pages-email-sent-js" */),
  "component---src-pages-fee-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/fee.js" /* webpackChunkName: "component---src-pages-fee-js" */),
  "component---src-pages-how-to-see-chart-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/how-to-see-chart.js" /* webpackChunkName: "component---src-pages-how-to-see-chart-js" */),
  "component---src-pages-how-to-start-trade-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/how-to-start-trade.js" /* webpackChunkName: "component---src-pages-how-to-start-trade-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issue-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/issue-bitcoin.js" /* webpackChunkName: "component---src-pages-issue-bitcoin-js" */),
  "component---src-pages-leverage-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/leverage.js" /* webpackChunkName: "component---src-pages-leverage-js" */),
  "component---src-pages-mechanism-of-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/mechanism-of-bitcoin.js" /* webpackChunkName: "component---src-pages-mechanism-of-bitcoin-js" */),
  "component---src-pages-merit-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/merit-bitcoin.js" /* webpackChunkName: "component---src-pages-merit-bitcoin-js" */),
  "component---src-pages-news-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-other-info-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/other-info-bitcoin.js" /* webpackChunkName: "component---src-pages-other-info-bitcoin-js" */),
  "component---src-pages-roadmap-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-security-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-trading-currency-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/trading-currency/bitcoin.js" /* webpackChunkName: "component---src-pages-trading-currency-bitcoin-js" */),
  "component---src-pages-trading-currency-bitcoincash-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/trading-currency/bitcoincash.js" /* webpackChunkName: "component---src-pages-trading-currency-bitcoincash-js" */),
  "component---src-pages-trading-currency-ethereum-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/trading-currency/ethereum.js" /* webpackChunkName: "component---src-pages-trading-currency-ethereum-js" */),
  "component---src-pages-trading-currency-index-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/trading-currency/index.js" /* webpackChunkName: "component---src-pages-trading-currency-index-js" */),
  "component---src-pages-trading-currency-qash-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/trading-currency/qash.js" /* webpackChunkName: "component---src-pages-trading-currency-qash-js" */),
  "component---src-pages-trading-currency-ripple-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/trading-currency/ripple.js" /* webpackChunkName: "component---src-pages-trading-currency-ripple-js" */),
  "component---src-pages-what-is-bitcoin-js": () => import("/home/circleci/liquid-jp-landing-page/src/pages/what-is-bitcoin.js" /* webpackChunkName: "component---src-pages-what-is-bitcoin-js" */)
}

